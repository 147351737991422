import 'normalize.css';

import { datadogRum } from '@datadog/browser-rum';
import React, { Suspense, useEffect } from 'react';
import { lazily } from 'react-lazily';
import { PopupLearnMoreContainer } from 'src/components/popup-learn-more-container';
import { WIDGET_INITIALIZED } from 'src/constants/common';
import { INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO } from 'src/constants/learn-more';
import {
  AnalyticsProvider,
  AnalyticsProviderProps,
  useAnalyticsContext,
} from 'src/context/analytics-context';
import {
  AppProvider,
  MerchantProps,
  useAppContext,
} from 'src/context/store/app-provider';
import { initializeDatadogRUMWebview } from 'src/utils/datadog';
import { getURLSearchParams } from 'src/utils/get-url-search-params';
import { isReferringFiniteNumber } from 'src/utils/is-referring-finite-number';
import { renderToDecorator } from 'src/utils/render-to-decorator';

const { NonStandartLearnMore } = lazily(
  () => import('src/components/non-standart-learn-more')
);
const { LearnMoreWithSchedule } = lazily(
  () => import('src/components/learn-more-with-schedule')
);
const { LearnMoreWithInstallmentsCalculator } = lazily(
  () => import('src/components/learn-more-with-installments-calculator')
);
const { LongTermContent } = lazily(
  () => import('src/components/long-term-content')
);

export function InstallmentsWebview() {
  const {
    dispatch,
    priceRaw,
    currency,
    merchantCode,
    publicKey,
    installmentsCount,
  } = useAppContext();
  const { sendLearnMoreOpenEvent } = useAnalyticsContext();

  useEffect(() => {
    sendLearnMoreOpenEvent();
    initializeDatadogRUMWebview([]);
  }, [sendLearnMoreOpenEvent]);

  useEffect(() => {
    dispatch({
      type: 'updateScheduleType',
    });
  }, [dispatch]);

  const isDefaultPromoInstallments =
    INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(installmentsCount);
  const shouldShowLTFLearnMore = !!currency && !!merchantCode && !!publicKey;
  const shouldShowSheduleLearnMore =
    !!priceRaw &&
    isReferringFiniteNumber(priceRaw) &&
    !!currency &&
    isDefaultPromoInstallments;
  const shouldShowNonStandartLearnMore =
    !!installmentsCount && !isDefaultPromoInstallments;

  const renderContent = () => {
    if (shouldShowLTFLearnMore) {
      return <LongTermContent />;
    }

    if (shouldShowSheduleLearnMore) {
      return <LearnMoreWithSchedule />;
    }

    if (shouldShowNonStandartLearnMore) {
      return <NonStandartLearnMore />;
    }

    return <LearnMoreWithInstallmentsCalculator />;
  };

  return (
    <PopupLearnMoreContainer
      style={{
        alignItems: 'stretch',
        ...(shouldShowLTFLearnMore
          ? {
              padding: 0,
              borderRadius: 0,
              height: '100%',
            }
          : null),
      }}
    >
      <Suspense fallback={null}>{renderContent()}</Suspense>
    </PopupLearnMoreContainer>
  );
}

declare global {
  interface Window {
    InstallmentsWebview: (
      props: { selector: string } & MerchantProps &
        Omit<AnalyticsProviderProps, 'children'>
    ) => void;
  }
}

window.InstallmentsWebview = ({ selector, ...props }) => {
  const searchParams = getURLSearchParams<typeof props>({
    lang: 'lang',
    currency: 'currency',
    installmentsCount: 'installmentsCount',
    price: 'price',
    partnerCharges: 'partnerCharges',
    publicKey: 'public_key',
    merchantCode: 'merchant_code',
  });

  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'InstallmentsWebview',
    ...props,
  });

  renderToDecorator(
    selector,
    <AppProvider {...props} {...searchParams} webview>
      <AnalyticsProvider props={{ selector, ...props, ...searchParams }}>
        <InstallmentsWebview />
      </AnalyticsProvider>
    </AppProvider>
  );
};
